.ql-toolbar {
    border-radius: 7px 7px 0 0;
    border: 0;
    padding: '15px';
    font-family: 'Public Sans, sans-serif';
    font-size: '1rem';
    color: '#647382';
}

.ql-container {
    border-radius: 0 0 7px 7px;
    border: 0;
    padding: '15px';
    font-family: 'Public Sans, sans-serif';
    
    height: 200px;
}

.ql-editor {
    color: #647382
}

.ql-container p {
    color: #647382;
    font-size: 1rem !important;
}
.ql-container p::before {
    content: "";
    color: green
}
.ql-container.ql-snow {
    border: 1px solid #3e464f !important;
    border-top: 0 !important;

}
.ql-toolbar.ql-snow {
    border: 1px solid #3e464f !important;
}
.ql-editor.ql-blank::before {
    color: #647382 !important;
    font-size: 1rem !important;
}
