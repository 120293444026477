/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.MuiModal-root {
  .MuiDialogActions-root {
    padding-left: 25px;
    padding-right: 25px;
  }
  .MuiDialog-paperWidthSm:not(.MuiDialog-paperFullScreen) {
    min-width: 600px;
    padding: 20px 20px;
    padding-bottom: 25px;
    overflow: hidden;
    .MuiAlert-standard {
      align-items: center;
    }
    .MuiDialogTitle-root {
      text-align: center;
    }
    #logo {
      max-width: 100px;
      height: auto;
      margin-right: 0px;
    }
    #zohoform {
      textarea {
        width: 100%;
        padding: 15px;
        min-height: 80px;
        max-height: 80px;
      }
    }
    h2 {
      font-size: 24px;
    }
    @media (max-width: $breakpoint-ms) {
      min-width: 99%;
    }
  }
}

.loading_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  .makeStyles-logo-1{
    width:100%;
  }
  .logo{
      max-width: 130px;
  }
}
.MuiLinearProgress-root{
  position: fixed !important;
  left: 0px;
  top: 0px;
  width: 100%;
}
